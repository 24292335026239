export const getNetworkErrorMessage = (err) => {
  console.log("You have an error =>", err);
  if (err?.response?.data?.errors?.name == "SequelizeUniqueConstraintError") {
    throw Error(
      err?.response?.data?.errors?.errors[0]?.message ?? err?.message
    );
  } else if (err?.response?.data?.message?.code) {
    throw Error(
      err?.response?.data?.message?.code
        ? err?.response?.data?.message?.code
        : err?.response?.data?.message
        ? err?.response?.data?.message
        : err?.message
    );
  } else if (err?.code === "ERR_BAD_REQUEST") {
    throw Error(
      err?.response?.data?.errors?.length > 0
        ? err?.response?.data?.errors[0]
        : err?.response?.data?.message ?? err?.message
    );
  } else if (err?.code === "ERR_NETWORK") {
    throw Error("Network error. Please check your internet connection.");
  } else if (err?.code === "ECONNABORTED") {
    throw Error("Request Timed out.");
  } else if (err?.code === "ERR_BAD_RESPONSE") {
    throw Error(
      err?.response?.data?.errors?.length > 0
        ? err?.response?.data?.errors[0]
        : err?.response?.data?.message ?? err?.message
    );
  } else {
    throw new Error(err);
  }
};
