import React from "react";
import { BsBox2 } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { IoIosHeartEmpty } from "react-icons/io";
import { MdOutlineRateReview } from "react-icons/md";
import { PiCaretDownBold } from "react-icons/pi";
import { RxTriangleUp } from "react-icons/rx";
import { TfiLock } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import {
  MY_BUSINESS_PROFILE,
  MY_ORDERS,
  MY_PROFILE,
  PENDING_REVIEWS,
  SAVED_ITEMS,
} from "../constants/data";
import AccountLinkItemMini from "./AccountLinkItemMini";
import LoginButton from "./LoginButton";

function LoginUserComponent({ user }) {
  const navigate = useNavigate();
  return (
    <>
      {!user && (
        <div className="relative flex gap-3 items-center login-container">
          <div className="w-[27px] h-[27px]">
            <img
              src="/assets/user_logo_3.png"
              alt="Wudify user logo"
              loading="lazy"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex gap-1 cursor-pointer hover:text-[#FF9D21] items-center">
            <span
              className="hover:text-[#FF9D21]"
              onClick={() => (window.location.href = "/auth/login")}
            >
              Login
            </span>
            {user && <PiCaretDownBold className="text-lg font-bold" />}
          </div>
        </div>
      )}
      {user && (
        <div
          className="relative flex gap-1 items-center login-container"
          style={{ zIndex: "100" }}
        >
          <div className="w-[27px] h-[27px] rounded-full">
            <img
              src={
                user?.logoImagePath
                  ? user?.logoImagePath
                  : "/assets/user_logo_3.png"
              }
              alt="Wudify user logo"
              loading="lazy"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="flex gap-1 cursor-pointer hover:text-[#FF9D21] items-center">
            <span className="hover:text-[#FF9D21]">{user?.firstName}</span>
            <PiCaretDownBold className="text-lg font-bold" />
          </div>
          <div
            className="bg-white w-[13em] right-0 absolute top-10 border p-3 rounded-md login-option"
            style={{ zIndex: "100" }}
          >
            <div
              className="w-[10em] absolute"
              style={{
                fontSize: "40px",
                fontWeight: "normal",
                top: "-18px",
                right: "0px",
              }}
            >
              <RxTriangleUp
                className="text-gray-100 relative"
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",

                  top: "-6px",
                  right: "-8em",
                }}
              />
            </div>
            <div className="flex gap-2 flex-col items-start ">
              <div className="p-3 bg-white">
                <AccountLinkItemMini
                  label="My Profile"
                  link={`/my-account?tab=${MY_PROFILE}`}
                >
                  <FaRegUser />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="My Business Profile"
                  link={`/my-account?tab=${MY_BUSINESS_PROFILE}`}
                >
                  <FaRegUser />
                </AccountLinkItemMini>
                <AccountLinkItemMini
                  label="Log out"
                  className="text-red-400"
                  link={`/auth/logout`}
                  onClick={() => navigate("/auth/logout")}
                >
                  <TfiLock />
                </AccountLinkItemMini>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginUserComponent;
