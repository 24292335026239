import React from "react";
import { Formik } from "formik";
import { Form } from "formik";
import HeadingAndDetails from "../HeadingAndDetails";
import CustomInputField from "../../../commons/CustomInputField";
import useBanks from "./../../../hooks/useBanks";
import CustomSelectField from "./../../../commons/CustomSelectField";

function BankDetails({ bankDetails, setBankDetails, formData, setFormData }) {
  const { banks } = useBanks();

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Bank Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          accountNumber: bankDetails?.accountNumber ?? "",
          accountName: bankDetails?.accountName ?? "",
          bankName: bankDetails?.bankName ?? "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.accountName) {
            errors.accountName = "Account name is required";
          }

          if (!values.accountNumber) {
            errors.accountNumber = "Account number is required";
          }
          if (!values.bankName) {
            errors.bankName = "Bank name is required";
          }

          if (Object.keys(errors).length == 0) {
            setBankDetails(values);

            localStorage.setItem(
              "formData",
              JSON.stringify({
                ...JSON.parse(localStorage.getItem("formData")),
                bankDetails: values,
              })
            );
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"accountName"}
                  required={true}
                  placeholder="Enter account name"
                  label={"Account Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"accountNumber"}
                  required={true}
                  placeholder="Enter account number"
                  label={"Account Number"}
                />
              </div>
              <div className="w-full">
                <CustomSelectField
                  name={"bankName"}
                  required={true}
                  placeholder="Enter bank name"
                  label={"Bank"}
                  keyValue={"name"}
                  valueKey={"name"}
                  selectedValue={
                    bankDetails?.bankName ?? formData?.bankDetails?.bankName
                  }
                  list={banks?.data ?? []}
                  onChange={(value) => setFieldValue("bankName", value)}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BankDetails;
