import { useMutation } from "@tanstack/react-query";
import { resendVerification } from "../services/auth-service";

function useResendVerification(openSnackbar) {
  const {
    mutateAsync: resendVendorVerification,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (email) => resendVerification(email),
    onSuccess: () => {
      openSnackbar(false, "A new verification mail has been sent to you");
    },
    onError: (error) => {
      openSnackbar(true, error?.message);
    },
  });
  return {
    resendVendorVerification,
    isLoading,
    error,
    data,
  };
}

export default useResendVerification;
