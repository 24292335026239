import React from "react";
import HeadingAndDetails from "../HeadingAndDetails";
import { Formik, Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import { validEmail } from "../../../helpers/validator";

function AccountManagerInformation({
  accountManagerInformation,
  setAccountManagerInformation,
  formData = {},
  setFormData = () => {},
}) {
  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Account Manager Information"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          email: accountManagerInformation?.email ?? "",
          phoneNumber: accountManagerInformation?.phoneNumber ?? "",
          firstName: accountManagerInformation?.firstName ?? "",
          lastName: accountManagerInformation?.lastName ?? "",
          alternativePhoneNumber:
            accountManagerInformation?.alternativePhoneNumber ?? "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (!validEmail(values.email)) {
            errors.email = "Invalid email address";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }
          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }

          if (Object.keys(errors).length == 0) {
            // setAccountManagerInformation(values);
            // setFormData((prev) => ({
            //   ...prev,
            //   accountManagerInformation: values,
            // }));
            // localStorage.setItem(
            //   "formData",
            //   JSON.stringify({
            //     ...formData,
            //     accountManagerInformation: values,
            //   })
            // );
          }

          setAccountManagerInformation(values);

          // setFormData((prev) => ({
          //   ...prev,
          //   accountManagerInformation: values,
          // }));

          // localStorage.setItem(
          //   "formData",
          //   JSON.stringify({
          //     ...formData,
          //     accountManagerInformation: values,
          //   })
          // );

          localStorage.setItem(
            "formData",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("formData")),
              accountManagerInformation: values,
            })
          );

          return errors;
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"phoneNumber"}
                  required={true}
                  placeholder="Enter phone number"
                  label={"Phone Number"}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"alternativePhoneNumber"}
                  required={false}
                  placeholder="Enter alternative phone number"
                  label={"Alternative Phone Number"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"email"}
                  required={true}
                  placeholder="Enter email address"
                  label={"Email Address (Business Email Preferably)"}
                />
              </div>
              <div className="w-full"></div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AccountManagerInformation;
