import React, { useState } from "react";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import HeadingAndDetails from "../HeadingAndDetails";
import { econtractStatements } from "../../../mock_data/e-contract";

function EContract({ onAcceptClick, isLoading }) {
  const [checked, setChecked] = useState(false);
  return (
    <div
      className="mt-6 text-left"
      style={{ height: "80vh", overflowY: "scroll" }}
    >
      <HeadingAndDetails title={"Accept E-Contract"} description={""} />

      {econtractStatements?.map(({ title, content }, index) => {
        return (
          <div className="text-[#98A2B3] text-[15px] font-[400] my-4">
            <p className="font-[600] text-black opacity-70 mb-2">{title}</p>
            <p>{content}</p>
          </div>
        );
      })}

      <div className="flex gap-4 items-center my-6 cursor-pointer">
        <p className="font-[500] text-[14px] flex items-center justify-between w-full">
          <label className="w-full flex gap-4 items-center" for="e-contract">
            <input
              type="checkbox"
              id="e-contract"
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="w-[18px] h-[18px] opacity-80 cursor-pointer"
            />
            {"I Accept All Terms & Conditions"}
          </label>
          <div className="w-3/4">
            {checked && (
              <RoundedMdButton
                disabled={isLoading}
                onClick={onAcceptClick}
                className="border bg-orange-400 text-white hover:opacity-60"
                label={isLoading ? "Please wait..." : "Accept and continue"}
              ></RoundedMdButton>
            )}
          </div>
        </p>
      </div>
    </div>
  );
}

export default EContract;
