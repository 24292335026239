import { useNavigate } from "react-router-dom";
import Layout from "../commons/Layout";
import RoundedMdButton from "../commons/RoundedMdButton";
import { useEffect } from "react";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/", {
        replace: true,
      });
    }, 2000);
  }, []);

  return (
    <Layout bgClassName="bg-gray-100 lg:!min-h-fit">
      <div className="flex justify-center items-center py-32">
        <div className="flex flex-col items-center">
          <p className="font-semibold text-[24px] max-md:text-[18px]">
            Page Not Found
          </p>
          <p className="pt-2 pb-4 text-[18px] max-md:text-[14px]">
            Oops! The page you are looking for does not exist.
          </p>
          <RoundedMdButton
            label="Go to HomePage"
            onClick={() => {
              navigate("/", {
                replace: true,
              });
            }}
            className={`!w-full border bg-orange-400 text-[white] flex justify-center`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
