import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { USER_QUERY_KEY } from "../constants/queryKeys";
import { logout } from "../services/auth-service";
import * as userLocalStore from "../local_store/userLocalStore";

function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutateAsync: logUserOut,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (logoutData) => logout(logoutData),
    onSuccess: (data) => {
      userLocalStore.removeUser();
      queryClient.setQueryData([USER_QUERY_KEY], null);
      navigate("/auth/logout");
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    logUserOut,
    isLoading,
    error,
    data,
  };
}

export default useLogout;
