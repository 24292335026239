import "./css/login.css";
import { useLocation } from "react-router-dom";
import useUser from "../hooks/useUser";
import LoginUserComponent from "./LoginUserComponent";
import { encryptData } from "../helpers/encryptor";

function TopHeader() {
  const location = useLocation();
  const { user } = useUser();

  const isHomePage = () => {
    if (location.pathname === "/") {
      return true;
    }

    return false;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="flex justify-between px-10 max-md:px-2 items-center py-4 fixed  top-0 w-full bg-white"
        style={{ zIndex: "10000" }}
      >
        {/* Left */}
        <div className="flex gap-10 max-sm:gap-2 items-center">
          {isHomePage() && (
            <div>
              <img
                alt="Wudify-logo"
                src="/assets/wudify_logo_top_header.png"
                loading="lazy"
                className="cursor-pointer w-[8em] h-[3.5em]"
                onClick={scrollToTop}
                style={{ objectFit: "contain" }}
              />
            </div>
          )}
          {!isHomePage() && (
            <a href="/">
              <div>
                <img
                  alt="Wudify-logo"
                  src="/assets/wudify_logo_top_header.png"
                  loading="lazy"
                  style={{ objectFit: "contain" }}
                  className="w-[8em] h-[3.5em]"
                />
              </div>
            </a>
          )}

          <a
            target={"_blank"}
            href={`${
              user?.userAccessToken
                ? `${
                    process.env.REACT_APP_STORE_FRONT_URL
                  }/auth/login?access=${encodeURIComponent(
                    encryptData(user?.userAccessToken)
                  )}`
                : `${process.env.REACT_APP_STORE_FRONT_URL}`
            }`}
          >
            <span className="font-[500] text-[15px] max-md:text-[12px]">
              Go to store
            </span>
          </a>
        </div>

        {/* Right */}
        <div className="flex gap-4 justify-end text-[15px] font-[500]">
          <LoginUserComponent user={user} />
        </div>
      </div>
    </>
  );
}

export default TopHeader;
