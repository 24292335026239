import React, { useEffect, useState } from "react";
import Layout from "../../commons/Layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import useVerifyEmail from "./../../hooks/useVerifyEmail";
import CustomBackdrop from "../../commons/CustomBackdrop";
import RoundedMdButton from "./../../commons/RoundedMdButton";
import { Form, Formik } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSnackBar from "../../commons/CustomSnackBar";
import useResendVerification from "../../hooks/useResendVerification";

function VerifyEmail() {
  const params = useParams();
  const navigate = useNavigate();

  const { token } = params;

  const { verifyUserEmail } = useVerifyEmail();

  const [isLoading, setIsLoading] = useState(true);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const openSnackbar = (isError, message) => {
    setSnackBarMessage(message);
    setOpenSnackBar(true);
    setIsError(isError ? true : false);
  };

  const { resendVendorVerification } = useResendVerification(openSnackbar);

  useEffect(() => {
    verifyUserEmail({ token })
      .then((res) => {
        navigate("/auth/email-verified", { replace: true });
      })
      .catch((err) => {
        console.log("Error verifying email", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  return (
    <Layout bgClassName="bg-gray-100">
      {isLoading && <CustomBackdrop open={isLoading} text={"Please wait"} />}
      {!isLoading && (
        <div className="flex items-center justify-center h-[80vh] max-md:h-[50vh] py-[1em]">
          <div className="p-5 rounded-lg w-[35%] max-lg:w-3/5 max-md:w-4/5 mx-auto">
            <div className="flex justify-center">
              <img src="/assets/rejected_icon.png" alt="" loading="lazy" />
            </div>
            <div className="text-[28px] max-lg:text-[22px] max-md:text-[16px] font-[600] mt-4 py-2">
              Account Verification failed
            </div>
            <div className="my-6">
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  resendVendorVerification({
                    email: values.email,
                  })
                    .then((res) => {
                      openSnackbar(
                        false,
                        "A new verification mail has been sent to you"
                      );
                      resetForm();
                    })
                    .catch((err) => {
                      openSnackbar(true, err?.message);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Email is required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  return errors;
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <CustomInputField
                      label={"Email Address"}
                      type="email"
                      placeholder="Please enter your email address"
                      name="email"
                      disabled={isSubmitting}
                    />
                    <RoundedMdButton
                      label={
                        isSubmitting ? "Please wait" : "Resend verification"
                      }
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-[#FF9D21] text-white hover:bg-orange-600"
                    >
                      {isSubmitting && (
                        <CustomThreeDotsSpinner
                          height="20"
                          width="20"
                          color="white"
                        />
                      )}
                    </RoundedMdButton>
                    <Link to="/onboarding">
                      <RoundedMdButton
                        label="Cancel"
                        disabled={isSubmitting}
                        className="bg-white text-black border hover:text-[#FF9D21]"
                      ></RoundedMdButton>
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </Layout>
  );
}

export default VerifyEmail;
