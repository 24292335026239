import React, { useState } from "react";
import CustomPriceDisplay from "../../../commons/CustomPriceDisplay";
import GeneralModal from "../../../commons/GeneralModal";
import StyledSpan from "../../../commons/StyledSpan";
import { capitalizeFirstLetter } from "../../../helpers/strings";
import { Form, Formik } from "formik";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import CustomThreeDotsSpinner from "../../../commons/spinners/CustomThreeDotsSpinner";
import CustomSelectField from "../../../commons/CustomSelectField";
import CustomTextField from "../../../commons/CustomTextField";
import useUpdateOrder from "./../../../hooks/useUpdateOrder";
import CustomSnackBar from "../../../commons/CustomSnackBar";
import { useNavigate } from "react-router-dom";

function OrderRowItem({
  order,
  user,
  openSnackBar,
  setOpenSnackBar,
  snackBarMessage,
  setSnackBarMessage,
  isError,
  setIsError,
}) {
  const navigate = useNavigate();

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const { updateOrderDetails } = useUpdateOrder();

  return (
    <>
      <tr className="bg-white cursor-pointer border-b">
        <th
          scope="row"
          onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
          className="px-6 py-4 max-md:px-4 max-md:py-2 font-medium text-gray-900 whitespace-nowrap"
        >
          <div className="flex items-center gap-6">
            <div>
              <p className="text-[13px] text-[#667085] font-[400] mt-1">
                #{order?.orderNumber}
              </p>
            </div>
          </div>
        </th>
        <td
          onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
          className="px-6 py-4 max-md:px-4 max-md:py-2 text-[14px] max-md:text-[12px] font-[500]"
        >
          {order?.Product?.name}
        </td>
        <td
          onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
          className="px-6 py-4 max-md:px-4 max-md:py-2 text-[14px] max-md:text-[12px] font-[500]"
        >
          {order?.quantity}
        </td>
        <td
          onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
          className="px-6 py-4 max-md:px-4 max-md:py-2 text-[14px] max-md:text-[12px] font-[500]"
        >
          <CustomPriceDisplay price={order?.subTotalPrice} />
        </td>
        <td className="px-6 py-4 max-md:px-4 max-md:py-2 flex gap-3 max-md:gap-1">
          <StyledSpan
            text={"Update delivery phase"}
            bgColor={"bg-[black]"}
            textColor="text-[white]"
            editable={true}
            onClick={() => setShowUpdateModal(!showUpdateModal)}
          />
          <StyledSpan
            text={capitalizeFirstLetter(order?.deliveryPhase)}
            bgColor={"bg-[#EFF8FF]"}
            textColor="text-[#2E90FA]"
          />
        </td>
        <td
          onClick={() => navigate(`/dashboard/orders/${order?.id}`)}
          className="px-6 py-4 max-md:px-4 max-md:py-2"
        >
          {order?.trackingStatus?.toLowerCase().includes("picked") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#fff6e0]"}
              textColor="text-[#82610e]"
            />
          )}
          {order?.trackingStatus?.toLowerCase().includes("shipped") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#FFFAEB]"}
              textColor="text-[#B54708]"
            />
          )}
          {order?.trackingStatus?.toLowerCase().includes("pending") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#FFFAEB]"}
              textColor="text-[#B54708]"
            />
          )}
          {order?.trackingStatus?.toLowerCase().includes("delivered") && (
            <StyledSpan
              text={capitalizeFirstLetter(order?.trackingStatus)}
              bgColor={"bg-[#ECFDF3]"}
              textColor="text-[#027A48]"
            />
          )}
        </td>
        <td className="px-6 py-4 max-md:px-4 max-md:py-2 flex gap-3 max-md:gap-1">
          <StyledSpan text={order?.createdAt} />
        </td>
      </tr>

      {/* Modal for status update */}
      <GeneralModal
        onClose={() => setShowUpdateModal(false)}
        showCloseButton={true}
        isOpen={showUpdateModal}
      >
        <div className="flex justify-center">
          <img src="/assets/cart-alternative-icon.png" />
        </div>
        <div className="text-black">
          <p className="font-[400] text-[14px] max-md:text-[12px] my-3">
            Update Order Delivery Phase
          </p>
          <p className="font-[500] text-[18px] max-md:text-[14px] mb-4">
            {order?.Product?.name}
          </p>
        </div>
        <div>
          <Formik
            initialValues={{
              accessToken: user?.accessToken,
              orderId: order?.id,
              deliveryPhase: order?.deliveryPhase ?? "",
              remarks: order?.vendorRemarks ?? "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateOrderDetails(values)
                .then((res) => {
                  setOpenSnackBar(true);
                  setSnackBarMessage(res?.data?.message);
                  setShowUpdateModal(false);
                  setIsError(false);
                  resetForm();
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.deliveryPhase) {
                errors.deliveryPhase = "Delivery phase is required";
              }
              if (!values.remarks) {
                errors.remarks = "Remarks is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <CustomSelectField
                  label={"Delivery Phase"}
                  type="text"
                  name="deliveryPhase"
                  placeholder="Select delivery phase"
                  disabled={isSubmitting}
                  selectedValue={values.deliveryPhase}
                  list={["ready for pick up", "picked up"]}
                  onChange={(value) => setFieldValue("deliveryPhase", value)}
                />
                <CustomTextField
                  label={"Comments"}
                  type="text"
                  name="remarks"
                  placeholder="Please enter some remarks"
                  disabled={isSubmitting}
                />

                <div className="flex gap-3 items-center">
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Cancel"}
                    className="border hover:bg-gray-100"
                    disabled={isSubmitting}
                    type="button"
                    onClick={() => setShowUpdateModal(false)}
                  ></RoundedMdButton>
                  <RoundedMdButton
                    label={isSubmitting ? "Please wait" : "Update"}
                    className="bg-[#FF9D21] text-white hover:bg-orange-600"
                    disabled={isSubmitting}
                    type="submit"
                    left={false}
                  >
                    {isSubmitting && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </RoundedMdButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </GeneralModal>
    </>
  );
}

export default OrderRowItem;
