import React, { useEffect, useReducer, useState } from "react";
import EContract from "./EContract";
import BankDetails from "./BankDetails";
import BusinessDetails from "./BusinessDetails";
import ContactDetails from "./ContactDetails";
import AccountManagerInformation from "./AccountManagerInformation";
import OwnerDetails from "./OwnerDetails";
import ProofOfAddress from "./ProofOfAddress";
import SmallHeadingTitle from "../SmallHeadingTitle";
import RadioItem from "../../../commons/RadioItem";
import HeadingAndDetails from "../HeadingAndDetails";
import PageTitle from "./../../../commons/PageTitle";
import RoundedMdButton from "./../../../commons/RoundedMdButton";
import GeneralModal from "../../../commons/GeneralModal";
import useVetVendor from "./../../../hooks/useVetVendor";
import {
  REGISTERED_BUSINESS,
  UN_REGISTERED_BUSINESS,
} from "../../../constants/texts";
import CustomSnackBar from "../../../commons/CustomSnackBar";
import useUpdateVendor from "./../../../hooks/useUpdateVendor";
import { useNavigate } from "react-router-dom";
import useVetUnregisteredVendor from "./../../../hooks/useVetUnregisteredVendor";
import UnregisteredBusinessDetails from "./UnregisteredBusinessDetails";

function BusinessVetting({
  productTypeState,
  setStepNumber,
  editing,
  user,
  formData,
  setFormData,
}) {
  const initialState = {
    radioValue: "",
  };

  const navigate = useNavigate();

  const { vetVendorBusiness } = useVetVendor();
  const { vetUnregistered } = useVetUnregisteredVendor();
  const { updateVendorLogo } = useUpdateVendor();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [businessType, setBusinessType] = useState(
    user?.VendorProofOfAddress
      ? UN_REGISTERED_BUSINESS
      : formData?.businessType
      ? formData?.businessType
      : REGISTERED_BUSINESS
  );
  const [logo, setLogo] = useState(
    user?.logoImagePath
      ? user?.logoImagePath
      : formData?.logo
      ? formData?.logo
      : "/assets/image_picker_icon.png"
  );

  // Form inputs here
  const [accountManagerInformation, setAccountManagerInformation] = useState(
    user
      ? user?.VendorAccountManagerInformation
      : formData?.accountManagerInformation
  );
  const [ownerDetails, setOwnerDetails] = useState(
    user ? user?.VendorOwnerDetail : formData?.ownerDetails
  );
  const [contactDetails, setContactDetails] = useState(
    user ? user?.VendorContactDetail : formData?.contactDetails
  );
  const [businessDetails, setBusinessDetails] = useState(
    user ? user?.VendorBusinessDetail : formData?.businessDetails
  );
  const [unregisteredBusinessDetails, setUnregisteredBusinessDetails] =
    useState(user ? user?.VendorBusinessDetail : formData?.businessDetails);
  const [bankDetails, setBankDetails] = useState(
    user ? user?.VendorBankDetail : formData?.bankDetails
  );
  const [proofOfAddressDetails, setProofOfAddressDetails] = useState(
    user ? user?.VendorProofOfAddress : formData?.proofOfAddressDetails
  );

  const [logoName, setLogoName] = useState(
    user?.logoImagePath ? user?.logoImagePath : "/assets/image_picker_icon.png"
  );

  function getInitialRadioState(user) {
    if (!user) {
      return "";
    }
    if (user?.VendorProofOfAddress) {
      return UN_REGISTERED_BUSINESS;
    } else if (
      user?.VendorBusinessDetail &&
      user?.VendorProofOfAddress === null
    ) {
      return REGISTERED_BUSINESS;
    }
  }

  // Functions here
  const handleVendorSubmission = (data) => {
    setSubmitting(true);
    let action = null;
    if (businessType == REGISTERED_BUSINESS) {
      action = vetVendorBusiness;
    } else if (businessType == UN_REGISTERED_BUSINESS) {
      action = vetUnregistered;
    } else {
      setOpenSnackBar(true);
      setSnackBarMessage("Something went wrong!");
      setIsError(true);
      return;
    }
    action({ ...data, accessToken: user?.accessToken })
      .then((res) => {
        setOpenSnackBar(true);
        setSnackBarMessage(res?.data?.message);
        setIsError(false);
        if (editing) {
          navigate("/my-account?tab=my-profile");
          localStorage.removeItem("onboardingStepNumber");
          localStorage.removeItem("formData");
        } else {
          setTimeout(() => {
            localStorage.removeItem("onboardingStepNumber");
            localStorage.removeItem("formData");
            navigate("/onboarding-completed", { replace: true });
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenSnackBar(true);
        setSnackBarMessage(err?.message);
        setIsError(true);
      })
      .finally(() => {
        setSubmitting(false);
        setShowContractModal(false);
      });
  };

  const getItem = (value) => {
    setBusinessType(value);
    // setFormData((prev) => ({
    //   ...prev,
    //   businessType: value,
    // }));

    localStorage.setItem(
      "formData",
      JSON.stringify({
        productTypes: productTypeState,
        businessType: value,
      })
    );
  };

  const setAndPreviewLogo = (e) => {
    if (e) {
      setLogo(e);
      setLogoName(URL.createObjectURL(e));

      // setFormData((prev) => ({
      //   ...prev,
      //   logo: e,
      //   logoName: URL.createObjectURL(e),
      // }));

      // localStorage.setItem(
      //   "formData",
      //   JSON.stringify({
      //     ...formData,
      //     logo: e,
      //     logoName: URL.createObjectURL(e),
      //   })
      // );
    }
  };

  const handleFileChange = (event, callback) => {
    const file = event.target.files[0];
    callback(file);
  };

  const handleUploadLogo = () => {
    setSubmitting(true);
    if (logo) {
      updateVendorLogo({
        logo,
        id: user?.id,
        containsFile: true,
        accessToken: user?.accessToken,
      })
        .then((res) => {
          setOpenSnackBar(true);
          setSnackBarMessage(res?.data?.message);
          setIsError(false);
        })
        .catch((err) => {
          setOpenSnackBar(true);
          setSnackBarMessage(err?.message);
          setIsError(true);
        })
        .finally(() => {
          setSubmitting(false);
          setShowContractModal(false);
        });
    } else {
      setOpenSnackBar(true);
      setSnackBarMessage("Please select an image");
      setIsError(true);
    }
  };

  const safeToSubmit = () => {
    return (
      businessType != "" &&
      accountManagerInformation &&
      bankDetails &&
      contactDetails &&
      (businessType == UN_REGISTERED_BUSINESS ? ownerDetails : true) &&
      (businessType == UN_REGISTERED_BUSINESS ? proofOfAddressDetails : true) &&
      (businessType == REGISTERED_BUSINESS ? businessDetails : true) &&
      (businessType == UN_REGISTERED_BUSINESS
        ? unregisteredBusinessDetails
        : true)
    );
  };

  const vettingData = {
    logo,
    productTypes:
      productTypeState?.list?.length > 0
        ? productTypeState
        : formData?.productTypes,
    containsFile: true,
    vendorId: user?.id,
    accountManagerInformation,
    ownerDetails,
    contactDetails,
    businessDetails,
    bankDetails,
    proofOfAddressDetails,
    unregisteredBusinessDetails,
    cacCertificate: businessDetails?.cacCertificate,
    verificationId: proofOfAddressDetails?.proofOfAddressFile,
  };

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    setAccountManagerInformation(
      user?.VendorAccountManagerInformation
        ? user?.VendorAccountManagerInformation
        : formData?.accountManagerInformation
    );
    setOwnerDetails(
      user?.VendorOwnerDetail ? user?.VendorOwnerDetail : formData?.ownerDetails
    );
    setContactDetails(
      user?.VendorContactDetail
        ? user?.VendorContactDetail
        : formData?.contactDetails
    );
    setBusinessDetails(
      user?.VendorBusinessDetail
        ? user?.VendorBusinessDetail
        : formData?.businessDetails
    );
    setUnregisteredBusinessDetails(
      user?.VendorBusinessDetail
        ? user?.VendorBusinessDetail
        : formData?.unregisteredBusinessDetails
    );
    setBankDetails(
      user?.VendorBankDetail ? user?.VendorBankDetail : formData?.bankDetails
    );
    setProofOfAddressDetails(
      user?.VendorProofOfAddress
        ? user?.VendorProofOfAddress
        : formData?.proofOfAddressDetails
    );
    setBusinessType(
      user?.VendorProofOfAddress
        ? UN_REGISTERED_BUSINESS
        : formData?.businessType
        ? formData?.businessType
        : REGISTERED_BUSINESS
    );
    setLogoName(
      user?.logoImagePath
        ? user?.logoImagePath
        : formData?.logoName ?? "/assets/image_picker_icon.png"
    );
    setLogo(user?.logoImagePath ? user?.logoImagePath : formData?.logo);
  }, [user, formData]);

  // useEffect(() => {
  //   setAccountManagerInformation(user?.VendorAccountManagerInformation);
  //   setOwnerDetails(user?.VendorOwnerDetail);
  //   setContactDetails(user?.VendorContactDetail);
  //   setBusinessDetails(user?.VendorBusinessDetail);
  //   setUnregisteredBusinessDetails(user?.VendorBusinessDetail);
  //   setBankDetails(user?.VendorBankDetail);
  //   setProofOfAddressDetails(user?.VendorProofOfAddress);
  //   setLogo(user?.logoImagePath);
  // }, [user]);

  return (
    <div className="text-left">
      <PageTitle title="Business Vetting" className="text-left" />
      <p className="text-left text-[#828282] text-[17px] max-lg:text-[15px] max-md:text-[13px]">
        Let's get some details about your business
      </p>

      {/* Registered or not */}
      <div className="mt-6">
        <SmallHeadingTitle title="Is your business a registered business?" />
        <div className="flex gap-6 max-sm:flex-col max-sm:gap-3 mt-2">
          <RadioItem
            value={REGISTERED_BUSINESS}
            selectedValue={businessType}
            label="Yes - I have registered with the CAC"
            onClick={() => {
              getItem(REGISTERED_BUSINESS);
            }}
          />
          <RadioItem
            value={UN_REGISTERED_BUSINESS}
            selectedValue={businessType}
            label="No - I have not registered with the CAC"
            onClick={() => {
              getItem(UN_REGISTERED_BUSINESS);
            }}
          />
        </div>
      </div>

      {/* Brand Logo */}
      <div className="mt-6">
        <HeadingAndDetails
          title={"Brand Logo"}
          description={"Upload your brand logo here"}
        />
        <div className="flex items-center mt-4 gap-8">
          <div className="file-upload relative">
            <div className="cursor-pointer bg-[#FFF2DB] w-[10em] h-[10em] max-md:w-[6em] max-md:h-[6em] rounded-full flex items-center justify-center">
              <img
                className={`${
                  logo
                    ? "cursor-pointer bg-[#FFF2DB] w-[10em] h-[10em] max-md:w-[6em] max-md:h-[6em] rounded-full flex items-center justify-center"
                    : ""
                }`}
                style={{ objectFit: "cover" }}
                src={logoName}
              />
            </div>
            <input
              type="file"
              onChange={(e) => handleFileChange(e, setAndPreviewLogo)}
            />
          </div>
          <div>
            <p className="text-[12px] opacity-40 font-[500] mb-2">
              {logo?.name}
            </p>
            <button
              disabled={submitting}
              onClick={handleUploadLogo}
              className="font-[500] hover:text-white hover:bg-[#F56630] text-[15px] max-md:text-[12px] rounded-lg border px-3 py-2 border-[#F56630] text-[#F56630]"
            >
              {submitting ? "Please wait" : "Upload logo"}
            </button>
          </div>
        </div>
      </div>

      {/* Account Managers Information */}
      <AccountManagerInformation
        setFormData={setFormData}
        formData={formData}
        accountManagerInformation={accountManagerInformation}
        setAccountManagerInformation={setAccountManagerInformation}
      />

      {/* Owner Details */}
      {businessType == UN_REGISTERED_BUSINESS && (
        <OwnerDetails
          setFormData={setFormData}
          formData={formData}
          ownerDetails={ownerDetails}
          setOwnerDetails={setOwnerDetails}
        />
      )}

      {/* Contact Details*/}
      <ContactDetails
        setFormData={setFormData}
        formData={formData}
        contactDetails={contactDetails}
        setContactDetails={setContactDetails}
      />

      {/* Business Details */}
      {businessType == REGISTERED_BUSINESS && (
        <BusinessDetails
          setFormData={setFormData}
          formData={formData}
          businessDetails={businessDetails}
          setBusinessDetails={setBusinessDetails}
        />
      )}

      {/* Unregistered Business Details */}
      {businessType == UN_REGISTERED_BUSINESS && (
        <UnregisteredBusinessDetails
          setFormData={setFormData}
          formData={formData}
          unregisteredBusinessDetails={unregisteredBusinessDetails}
          setUnregisteredBusinessDetails={setUnregisteredBusinessDetails}
        />
      )}

      {/* Bank Details */}
      <BankDetails
        setFormData={setFormData}
        formData={formData}
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
      />

      {/* Proof of Address */}
      {businessType == UN_REGISTERED_BUSINESS && (
        <ProofOfAddress
          setFormData={setFormData}
          formData={formData}
          proofOfAddressDetails={proofOfAddressDetails}
          setProofOfAddressDetails={setProofOfAddressDetails}
        />
      )}

      {/* Accept E-Contract */}
      {/* <p
        className="text-blue-300 font-[500] text-[16px] cursor-pointer"
        onClick={() => setShowContractModal(!showContractModal)}
      >
        Read and accept terms and conditions
      </p> */}
      <GeneralModal
        isOpen={showContractModal}
        showCloseButton={true}
        onClose={() => setShowContractModal(false)}
      >
        <EContract
          isLoading={submitting}
          onAcceptClick={() => handleVendorSubmission(vettingData)}
        />
      </GeneralModal>

      {/*  */}
      <div className="flex justify-between mt-8">
        <div className="w-[20%]">
          <RoundedMdButton
            label={"Previous"}
            disabled={submitting}
            onClick={() => {
              localStorage.setItem("onboardingStepNumber", 2);
              setStepNumber(2);
            }}
          />
        </div>
        <div className="w-[20%]">
          <RoundedMdButton
            label={"Submit"}
            disabled={!safeToSubmit()}
            className="bg-[#FF9D21] font-[500] text-[16px] max-md:text-[13px] text-white"
            onClick={() => {
              setShowContractModal(!showContractModal);
              // if (
              //   businessType === UN_REGISTERED_BUSINESS &&
              //   (!proofOfAddressDetails?.proofOfAddressFile?.size ||
              //     !ownerDetails?.meansOfIdentificationFile?.size)
              // ) {
              //   setIsError(true);
              //   setOpenSnackBar(true);
              //   setSnackBarMessage(
              //     "Please fill proof of address and owner means of identification"
              //   );
              // } else if (
              //   businessType === REGISTERED_BUSINESS &&
              //   !businessDetails?.cacCertificate?.size
              // ) {
              //   setIsError(true);
              //   setOpenSnackBar(true);
              //   setSnackBarMessage("Please fill business CAC Certificate");
              // } else {
              //   setShowContractModal(!showContractModal);
              // }
            }}
          />
        </div>
      </div>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
}

export default BusinessVetting;
