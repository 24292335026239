import { CurrentStep, UnvisitedStep, VisitedStep } from "../svgs";

function OnboardingSteps({
  currentIndex,
  widthClass = "w-full",
  stepList = [],
}) {
  const getStepIcons = () => {
    return stepList.map((step, index) => {
      if (index == currentIndex) {
        return (
          <div className="flex gap-2 text-[#828282] text-[17px] max-lg:text-[15px] max-md:text-[13px] font-[400] items-center min-w-fit">
            <div className="flex gap-3 items-center">
              <CurrentStep />
              <p className="">{step}</p>
            </div>
            {index < stepList.length - 1 && (
              <div className="w-[3em] h-[2px] bg-[#828282] opacity-30"></div>
            )}
            {index == stepList.length - 1 && <div className="w-[3em]"></div>}
          </div>
        );
      }
      if (index < currentIndex) {
        return (
          <div className="flex gap-2 text-[#828282] justify-around text-[17px] max-lg:text-[15px] max-md:text-[13px] font-[400] items-center min-w-min-w-min-w-fit">
            <div className="flex gap-3 items-center">
              <VisitedStep />
              <p className="whitespace-nowrap">{step}</p>
            </div>
            {index < stepList.length - 1 && (
              <div className="w-[3em] h-[2px] bg-[#828282] opacity-30"></div>
            )}
            {index == stepList.length - 1 && <div className="w-[3em]"></div>}
          </div>
        );
      }

      return (
        <div className="flex gap-2 text-[#828282] justify-around text-[17px] max-lg:text-[15px] max-md:text-[13px] font-[400] items-center min-w-fit">
          <div className="flex gap-3 items-center">
            <UnvisitedStep />
            <p className="whitespace-nowrap">{step}</p>
          </div>

          {index < stepList.length - 1 && (
            <div className="w-[3em] h-[2px] bg-[#828282] opacity-30"></div>
          )}
          {index == stepList.length - 1 && <div className="w-[3em]"></div>}
        </div>
      );
    });
  };

  return (
    <div className={`flex gap-2 overflow-x-auto mx-auto ${widthClass}`}>
      {getStepIcons()}
    </div>
  );
}

export default OnboardingSteps;
