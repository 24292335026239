import React, { useState, useEffect } from "react";
import Layout from "../../../commons/Layout";
import PageBreadCrumb from "../../../commons/PageBreadCrumb";
import PageTitle from "../../../commons/PageTitle";
import NavButton from "../NavButton";
import { GoPlus } from "react-icons/go";
import InfoCard from "./../InfoCard";
import NavTab from "./../NavTab";
import PaymentRowItem from "./PaymentRowItem";
import { PENDING } from "./../../../constants/texts";
import AllNavBars from "../AllNavBars";
import useUser from "./../../../hooks/useUser";
import useVendorOrders from "../../../hooks/useVendorOrders";
import { APPROVED, COMPLETED, IN_PROGRESS } from "../../../constants/texts";
import CustomPriceDisplay from "../../../commons/CustomPriceDisplay";
import OrderRowItem from "../orders/OrderRowItem";
import CustomNoRecordFound from "../../../commons/CustomNoRecordFound";
import NotFoundSVG from "../../../commons/NotFoundSVG";
import Pagination from "./../../../commons/Pagination";
import useSingleVendor from "../../../hooks/useSingleVendor";

function Payments() {
  const [selected, setSelected] = useState("");
  const { user } = useUser();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { vendorOrderItems, isLoading } = useVendorOrders(
    user,
    pageSize,
    pageNumber,
    "",
    ["", ""],
    ""
  );

  const { singleVendor } = useSingleVendor({
    vendorId: user?.id,
    token: user?.token,
    otherDetails: true,
  });

  useEffect(() => {
    if (selected == IN_PROGRESS) {
      setFilteredOrders(vendorOrderItems?.inProgressOrders);
    } else if (selected == COMPLETED) {
      setFilteredOrders(vendorOrderItems?.completedOrders);
    } else {
      setFilteredOrders(vendorOrderItems?.orders);
    }
  }, [vendorOrderItems, selected]);

  return (
    <Layout>
      <div className="w-[78%] max-lg:w-[85%] max-md:w-[95%] mx-auto mt-5">
        <PageBreadCrumb
          isActiveIndex={1}
          mainText="Home"
          subText={"Vendor Dashboard"}
        />

        <AllNavBars
          user={user}
          pageDescription={"View and manage your payments"}
          selectedIndex={3}
        />

        <div className="flex justify-between items-center gap-3 mt-4 w-full overflow-x-auto">
          <InfoCard
            text="Total Sales"
            value={<CustomPriceDisplay price={singleVendor?.totalSales ?? 0} />}
            icon={"/assets/payments_icon.png"}
          />
          <InfoCard
            text="Pending Payment"
            value={
              <CustomPriceDisplay price={singleVendor?.pendingPayouts ?? 0} />
            }
            icon={"/assets/pending_icon.png"}
          />
          <InfoCard
            text="Completed Payment"
            value={
              <CustomPriceDisplay price={singleVendor?.completedPayouts ?? 0} />
            }
            icon={"/assets/approved_icon.png"}
          />
        </div>

        <div className="flex items-center gap-3 mt-6 w-full">
          <NavTab
            label={"All Orders"}
            value={vendorOrderItems?.totalRecords ?? 0}
            isSelected={selected}
            actualValue={""}
            onClick={() => setSelected("")}
          />
          <NavTab
            label={"In Progress"}
            value={vendorOrderItems?.inProgressOrders?.length ?? 0}
            isSelected={selected}
            actualValue={IN_PROGRESS}
            onClick={() => setSelected(IN_PROGRESS)}
          />
          <NavTab
            label={"Completed Orders"}
            value={vendorOrderItems?.completedOrders?.length ?? 0}
            isSelected={selected}
            actualValue={COMPLETED}
            onClick={() => setSelected(COMPLETED)}
          />
        </div>
        <div className="w-full h-[1.2px] bg-gray-100 mt-[-1px]"></div>

        <div class="relative overflow-x-auto my-8">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Order No
                </th>
                <th scope="col" class="px-6 py-3">
                  Product
                </th>
                <th scope="col" class="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders?.map((orderItem) => {
                return <PaymentRowItem order={orderItem} />;
              })}
            </tbody>
          </table>

          {!isLoading && filteredOrders && filteredOrders?.length === 0 && (
            <div className="flex items-center justify-center py-24 max-md:py-12">
              <CustomNoRecordFound
                showButton={false}
                buttonText={"Got it"}
                label={`No ${selected} payments found`}
              >
                <NotFoundSVG />
              </CustomNoRecordFound>
            </div>
          )}

          {!isLoading && filteredOrders && filteredOrders?.length > 0 && (
            <div className="mt-4 mb-5">
              <Pagination
                onPageChange={setPageNumber}
                currentPage={pageNumber}
                totalCount={filteredOrders?.length ?? 0}
                pageSize={pageSize}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Payments;
