import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PRODUCTS_QUERY_KEY } from "../constants/queryKeys";
import { deleteProduct } from "../services/products-services";

function useDeleteProduct() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: deleteProductDetails,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: ({ accessToken, productId }) =>
      deleteProduct({ accessToken, productId }),
    onSuccess: (data) => {
      queryClient.invalidateQueries([PRODUCTS_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    deleteProductDetails,
    isLoading,
    error,
    data,
  };
}

export default useDeleteProduct;
