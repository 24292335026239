import React, { useEffect, useReducer, useState } from "react";
import Layout from "../../../commons/Layout";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import ProductType from "./ProductType";
import { addItemToList } from "./../../../reducers/listCreationReducer/actions";
import { listCreationReducer } from "./../../../reducers/listCreationReducer/index";
import OnboardingNavigator from "./OnboardingNavigator";
import BusinessVetting from "./BusinessVetting";
import useUser from "./../../../hooks/useUser";
import BusinessVettingEdit from "./BusinessVettingEdit";

function OnboardingTwo({ editing = false }) {
  const [stepNumber, setStepNumber] = useState(
    localStorage.getItem("onboardingStepNumber")
      ? parseInt(localStorage.getItem("onboardingStepNumber"))
      : 2
  );
  const [formData, setFormData] = useState({ productTypes: {} });

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const { user } = useUser();

  const initialState = {
    list:
      user?.VendorBusinessDetail?.productSpecification?.split(",")?.length > 0
        ? user?.VendorBusinessDetail?.productSpecification?.split(",")
        : formData?.productTypes?.list?.length > 0
        ? formData?.productTypes?.list
        : [],
  };

  const [state, dispatch] = useReducer(listCreationReducer, initialState);

  const addToCheckedItems = (value) => {
    dispatch(addItemToList(value));
  };

  return (
    <Layout>
      <div className="my-8 w-3/4 max-md:w-[90%] mx-auto">
        <OnboardingNavigator
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          showBackButtonIndex={2}
          complete
          editing={editing}
        />

        {/* Product type */}
        {stepNumber == 2 && (
          <div className="mt-12 w-full">
            <ProductType
              setStepNumber={setStepNumber}
              checkedItems={state?.list}
              addToCheckedItems={addToCheckedItems}
            />
            <div className="flex justify-end mt-8">
              <div className="w-[20%]">
                <RoundedMdButton
                  label={"Next"}
                  disabled={state?.list?.length == 0}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                  onClick={() => {
                    localStorage.setItem("onboardingStepNumber", 3);
                    // setFormData((prev) => ({
                    //   ...prev,
                    //   productTypes: state,
                    // }));
                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        businessType:
                          formData?.businessType ?? "REGISTERED_BUSINESS",
                        productTypes: state,
                      })
                    );
                    setStepNumber(3);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/* Business vetting */}
        {stepNumber == 3 && !editing && (
          <div className="mt-12 w-full mb-12">
            <BusinessVetting
              user={user}
              editing={editing}
              productTypeState={state}
              setStepNumber={setStepNumber}
              setFormData={setFormData}
              formData={formData}
            />
          </div>
        )}

        {/* Business vetting for editing */}
        {stepNumber === 3 && editing && (
          <div className="mt-12 w-full mb-12">
            <BusinessVettingEdit
              user={user}
              editing={editing}
              productTypeState={state}
              setStepNumber={setStepNumber}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}

export default OnboardingTwo;
