import React, { useState, useRef } from "react";
import HeadingAndDetails from "./../HeadingAndDetails";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import CustomSelectField from "../../../commons/CustomSelectField";
import ConfirmationModal from "./../../../commons/modals/ConfirmationModal";
import { getFileTypesFriendly } from "../../../helpers/fileUpload";
import RoundedMdButton from "./../../../commons/RoundedMdButton";
import {
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  DOCX_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../../../constants/texts";

const fileTypes = [
  PNG_FILE_TYPE,
  JPG_FILE_TYPE,
  JPEG_FILE_TYPE,
  WEBP_FILE_TYPE,
  PDF_FILE_TYPE,
  DOCX_FILE_TYPE,
];

const proofOfAddressDocumentTypes = [
  "Utility",
  "Bank statement",
  "Tax assessment",
  "Cable TV bill",
  "Letter",
];

function ProofOfAddress({
  proofOfAddressDetails,
  setProofOfAddressDetails,
  formData,
}) {
  const [fileInput, setFileInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const fileRef = useRef();

  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Proof of Address"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          type: "",
          proofOfAddressFile: "",
        }}
        validate={(values) => {
          const errors = {};

          // if (
          //   values.proofOfAddressFile === null ||
          //   !values.proofOfAddressFile?.size
          // ) {
          //   setFileInput("");
          //   setOpenModal(true);
          // }

          // if (!values.type) {
          //   errors.type = "Proof of address is required";
          // }

          // if (!values.proofOfAddressFile?.size) {
          //   errors.proofOfAddressFile = "Proof of address file is required";
          // }

          if (Object.keys(errors).length == 0) {
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldError, setFieldValue }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-start gap-4">
              <div className="w-full">
                <CustomSelectField
                  name={"type"}
                  required={false}
                  placeholder="Proof of address"
                  label={"Select Proof of Address"}
                  list={proofOfAddressDocumentTypes}
                  onChange={(value) => {
                    setProofOfAddressDetails((prev) => ({
                      ...prev,
                      type: value,
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        proofOfAddressDetails: {
                          ...proofOfAddressDetails,
                          type: value,
                        },
                      })
                    );
                    setFieldValue("type", value);
                  }}
                  selectedValue={
                    proofOfAddressDetails?.type ??
                    formData?.proofOfAddressDetails?.type
                  }
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"proofOfAddressFile"}
                  required={false}
                  type="file"
                  placeholder=""
                  label={"Attach Proof of Address"}
                  fileTypes={fileTypes}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  inputRef={fileRef}
                  fileCallback={setFileInput}
                  onChange={(e) => {
                    setProofOfAddressDetails((prev) => ({
                      ...prev,
                      proofOfAddressFile: e.target.files[0],
                    }));

                    localStorage.setItem(
                      "formData",
                      JSON.stringify({
                        ...JSON.parse(localStorage.getItem("formData")),
                        proofOfAddressDetails: {
                          ...proofOfAddressDetails,
                          proofOfAddressFile: e.target.files[0],
                        },
                      })
                    );
                  }}
                />
                <div className="text-[#98A2B3] font-[400] text-[14px]">
                  <p className="">
                    Proof of address can be any of these documents, not more
                    than 6 months old:
                  </p>
                  <p>
                    <ul>
                      <ol>1. Utility bill for services to the address.</ol>
                      <ol>2. Bank statement showing current address.</ol>
                      <ol>3. Tax assessment.</ol>
                      <ol>4. Cable TV bill such as DSTV bill.</ol>
                      <ol>5. Letter from a public authority.</ol>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ConfirmationModal
        isOpen={openModal && fileInput != ""}
        type={"warning"}
        title={"File upload error"}
        description={""}
        subText={
          "Invalid file type. Acceptable types (" +
          getFileTypesFriendly(fileTypes) +
          ")"
        }
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="my-5">
          <RoundedMdButton
            label={"OK"}
            onClick={() => {
              setOpenModal(false);
            }}
          />
        </div>
      </ConfirmationModal>
    </div>
  );
}

export default ProofOfAddress;
