import React from "react";
import { Formik } from "formik";
import { Form } from "formik";
import HeadingAndDetails from "../HeadingAndDetails";
import CustomInputField from "../../../commons/CustomInputField";
import {
  validEmail,
  validFBUrl,
  validLinkedinUrl,
  validTwitterUrl,
} from "./../../../helpers/validator";

function ContactDetails({
  contactDetails,
  setContactDetails,
  formData = {},
  setFormData = () => {},
}) {
  return (
    <div className="mt-6">
      <HeadingAndDetails
        title={"Contact Details"}
        description={"Kindly complete the form below"}
      />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize
        initialValues={{
          email: contactDetails?.email ?? "",
          alternativeEmail: contactDetails?.alternativeEmail ?? "",
          websiteUrl: contactDetails?.websiteUrl ?? "",
          facebookUrl: contactDetails?.facebookUrl ?? "",
          linkedinUrl: contactDetails?.linkedinUrl ?? "",
          twitterUrl: contactDetails?.twitterUrl ?? "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Business email is required";
          } else if (!validEmail(values.email)) {
            errors.email = "Invalid email address";
          }

          if (values.alternativeEmail) {
            if (!validEmail(values.alternativeEmail)) {
              errors.alternativeEmail = "Invalid email address";
            }
          }

          if (values.facebookUrl) {
            if (!validFBUrl(values.facebookUrl)) {
              errors.facebookUrl = "Invalid Facebook url";
            }
          }

          if (values.twitterUrl) {
            if (!validTwitterUrl(values.twitterUrl)) {
              errors.twitterUrl = "Invalid Twitter url";
            }
          }

          if (values.linkedinUrl) {
            if (!validLinkedinUrl(values.linkedinUrl)) {
              errors.linkedinUrl = "Invalid LinkedIn url";
            }
          }

          if (Object.keys(errors).length == 0) {
            // setContactDetails(values);
            // setFormData((prev) => ({
            //   ...prev,
            //   contactDetails: values,
            // }));
            // localStorage.setItem(
            //   "formData",
            //   JSON.stringify({
            //     ...formData,
            //     contactDetails: values,
            //   })
            // );
          }

          setContactDetails(values);

          // setFormData((prev) => ({
          //   ...prev,
          //   contactDetails: values,
          // }));

          // localStorage.setItem(
          //   "formData",
          //   JSON.stringify({
          //     ...formData,
          //     contactDetails: values,
          //   })
          // );

          localStorage.setItem(
            "formData",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("formData")),
              contactDetails: values,
            })
          );

          return errors;
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"email"}
                  required={true}
                  placeholder="Enter business email"
                  label={"Business Email"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"alternativeEmail"}
                  required={false}
                  placeholder="Enter alternative email"
                  label={"Alternative Email"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"websiteUrl"}
                  required={false}
                  placeholder="Enter website url"
                  label={"Website Url"}
                />
              </div>
            </div>
            <div className="flex max-md:flex-col max-md:gap-0 items-center gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"facebookUrl"}
                  required={false}
                  placeholder="Enter Facebook url"
                  label={"Facebook Url"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"linkedinUrl"}
                  required={false}
                  placeholder="Enter LinkedIn url"
                  label={"Linkedin Url"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"twitterUrl"}
                  required={false}
                  placeholder="Enter Twitter url"
                  label={"Twitter Url"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactDetails;
