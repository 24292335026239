export const LIGHT_BLUE_COLOR = "#318EC9";
export const DOTS = "...";
export const MOST_POPULAR = "MOST_POPULAR";
export const NEW_TO_OLD = "NEW_TO_OLD";
export const OLD_TO_NEW = "OLD_TO_NEW";

export const PNG_FILE_TYPE = "image/png";
export const TEXT_FILE_TYPE = "text/plain";
export const JPEG_FILE_TYPE = "image/jpeg";
export const JPG_FILE_TYPE = "image/jpg";
export const WEBP_FILE_TYPE = "image/webp";

export const PDF_FILE_TYPE = "application/pdf";
export const DOCX_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const CURRENCY_SYMBOL = "₦";

export const REGISTERED_BUSINESS = "REGISTERED_BUSINESS";
export const UN_REGISTERED_BUSINESS = "UN_REGISTERED_BUSINESS";

export const FURNITURE = "FURNITURE";
export const DECOR_AND_MORE = "DECOR_AND_MORE";
export const LIGHTING = "LIGHTING";

export const APPROVED = "approved";
export const PENDING = "pending";
export const REJECTED = "rejected";

export const IN_PROGRESS = "in progress";
export const COMPLETED = "completed";

export const SELLER = "SELLER";
export const MAKER = "MAKER";
export const INSTALLER = "INSTALLER";

export const MIN_SHORT_DESCRIPTION = 10;
export const MAX_SHORT_DESCRIPTION = 200;

export const MIN_FULL_DESCRIPTION = 0;
export const MAX_FULL_DESCRIPTION = 1000;

export const GENERAL_BLUE_HEX = "#318EC9";
